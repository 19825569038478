import Vue from "vue";
import Vuetify from "vuetify"; // DEFINITELY NOT "vuetify/lib"
import "vuetify/dist/vuetify.min.css"; // Add this line
//import "@mdi/font/css/materialdesignicons.css"; //material icons

Vue.use(Vuetify);


const opts = {
    options: {
      customProperties: true
    },
    theme: {
      themes: {
        light: {
          primary: '#582468',
          secondary: '#212121',
          accent: '#1993E0',
          info: '#E0E0E0',
          lightgrey: '#E0E0E0',
          darkgrey: '#4f4f4f',
          appbackground: '#F2F2F2',
          success: '#00942A',
          warning: '#E04B0D',
          error: '#E10203',
        },
      },
    },
    breakpoint: {
      thresholds: {
        xs: 340,
        sm: 600, // 540,
        md: 800,
        lg: 1500,  // sets Ipad 
      },
      scrollBarWidth: 24,
    },
  };

export default new Vuetify(opts);