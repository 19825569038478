import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '../node_modules/animate.css/animate.min.css'; // import animate css

Vue.config.productionTip = false

import mapboxgl from "mapbox-gl";
Vue.prototype.$mapboxgl = mapboxgl;

import moment from "moment";
Vue.prototype.$moment = moment;

import Paho from 'paho-mqtt';
Vue.prototype.$Paho = Paho;

// alertbox mixin import and use
import mapbox_mixin from '@/modules/mapbox/mapbox_mixin.js';
Vue.use(mapbox_mixin);

import axios from 'axios';
Vue.prototype.$axios = axios;

// alertbox component import and register
import mapbox_component from '@/modules/mapbox/mapbox_component.vue';
Vue.component('mapbox', mapbox_component);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
