import { mapActions } from "vuex";
const mapbox_mixins = {       
    data: () => ({}),
    methods: {
        ...mapActions({
            ACT_marker_create: 'mapbox_store/ACT_marker_create',
            ACT_marker_update: 'mapbox_store/ACT_marker_update',
            ACT_marker_delete: 'mapbox_store/ACT_marker_delete',
            ACT_nodes: 'mapbox_store/ACT_nodes',
            ACT_nodesPM: 'mapbox_store/ACT_nodesPM',

        }),
        MIX_update_map() {
            // center, mapstyle, zoom
            var payload = {};
            payload.center = [-3.9626085620764164, 51.881342123360376]; //[-4.2283417,51.7089602];
            payload.style = "mapbox://styles/mapbox/light-v10"; //mapbox://styles/mapbox/outdoors-v11
            payload.zoom = 8.9;
            this.$store.dispatch("mapbox_store/ACT_update_map", payload);
            //console.log('MIX_update_map')
        },
        // MIX_map_zoomin() {
        //     var payload = {}
        //     payload ;
        //     this.$store.dispatch("mapbox_store/ACT_update_zoom", payload);    
        // }
        ACT_update_layerSelected (payload) {
            this.$store.dispatch("mapbox_store/ACT_update_layerSelected", payload);            
        },
//        MIX_
        MIX_marker_create(id, payload) {
            console.log('MIX_marker_create')
            payload.id = id;
            this.ACT_marker_create (payload);
        },
        MIX_marker_update(id, payload) {
            payload.id = id;
            this.ACT_marker_update (payload);
        },
        MIX_marker_delete(id, payload) {
            payload.id = id;
            this.ACT_marker_delete (payload);
        },
        MIX_nodes(payload) { 
            let t = this;
                t.ACT_nodes (payload);
        },
        MIX_nodesPM(payload) { 
            this.ACT_nodesPM (payload);
        }        

    },
}

export default {
    install (Vue) {
        Vue.mixin(mapbox_mixins)
    }
};

 

  
  