import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import mapbox_store from "@/modules/mapbox/mapbox_store.js";

export default new Vuex.Store({
    modules: {
        mapbox_store: mapbox_store,
    },
    state: {
        nodesInstall: [],
        nodesStatus: []
    },
    mutations:{
        // nodesStatus
        create_nodesStatus (state, { payload }) { state.nodesStatus.push (payload) },
        update_nodesStatus (state, { index, payload }) { Vue.set(state.nodesStatus, index, payload) },
        delete_nodesStatus (state, { index }) { state.nodesStatus.splice(index, 1) },
        // nodesInstall
        create_nodesInstall (state, { payload }) { state.nodesInstall.push (payload) },
        update_nodesInstall (state, { index, payload }) { Vue.set(state.nodesInstall, index, payload) },
        delete_nodesInstall (state, { index }) { state.nodesInstall.splice(index, 1) },
    },
    actions: {
        action_nodesStatus({commit}, message) {
            var topicSplit = message.destinationName.split('/');
            var id = topicSplit[topicSplit.length - 2];
            var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
            var index = this.state.nodesStatus.findIndex(i => i.id === id); // FIND INDEX OF MINI DETAILS
            // var timeNow = this.$moment();
            // var timeEnd = this.$moment(payload.updated, 'x')
            // switch (true) {
            //     case (timeNow.diff(timeEnd, 'minutes') > 30):
            //         payload.code = -1;
            //         break;
            //     case (timeNow.diff(timeEnd, 'minutes') < 30):
            //         payload.code = 1;
            //         break;
            //     default:
            //         payload.code = 0;
            //         break;
            // }
            // IF NODE STATUS DOES NOT EXIST
            if (index === -1) {
                // CREATE NODE STATUS
                console.log('create_nodeStatus')
                commit('create_nodesStatus', { payload })
                //this.nodesStatus.push (payload)
            } else {
                if (JSON.stringify(payload) === '{}') {
                    // DELETE NODE STATUS
                    //commit('delete_nodesStatus', { index })                            
                    // this.nodesStatus.splice(index, 1);
                } else {
                    // UPDATE NODE STATUS
                    //commit('update_nodesStatus', { index, payload })                            
                    //this.$set(this.nodesStatus, index, payload);                        
                }
            }
          },
          // nodesInstall
          action_nodesInstall({commit}, message) {
            var topicSplit = message.destinationName.split('/');
            var id = topicSplit[topicSplit.length - 2];
            var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
            var index = this.state.nodesInstall.findIndex(i => i.id === id); // FIND INDEX OF MINI DETAILS
            // IF NODE STATUS DOES NOT EXIST
            if (index === -1) {
                commit('create_nodesInstall', { payload }) // CREATE NODE STATUS
            } else {
                if (JSON.stringify(payload) === '{}') {
                    commit('delete_nodesInstall', { index }) // DELETE NODE STATUS                            
                } else {
                    commit('update_nodesInstall', { index, payload }) // UPDATE NODE STATUS                            
                }
            }
          },
    },
    getters: {
        GET_nodesInstall (state) { return state.nodesInstall },
        GET_nodesStatus (state) { return state.nodesStatus },
        GET_mapboxData (state) {
            var mapboxData = []
  //          var sensorsStatus = []
//            sensorsStatus = state.sensorsStatus
//            console.log('here1')
            // if (JSON.stringify(state.sensorsStatus) !== '[]') {
            //     console.log('sensorsStatus = ' + state.nodesStatus)
            // } else {
            //     console.log('here2')
            // }
            for (var i = 0; i < state.nodesInstall.length; i++) {
                var sensorNode = state.nodesInstall[i]
                //var sensorStatusIndex = 0
                //console.log(sensorNode.id)
                // sensorStatusIndex = state.nodesStatus.indexOf(function (x) {
                //     return x.id === sensorNode.id
                // })
                var mapboxFeature = {}
                mapboxFeature.type = 'Feature';
                mapboxFeature.geometry = {};
                mapboxFeature.geometry.type = 'Point';
                mapboxFeature.geometry.coordinates = [sensorNode.location.lon, sensorNode.location.lat]
                mapboxFeature.properties = {};
                mapboxFeature.properties.name = sensorNode.name;
                mapboxFeature.properties.id = sensorNode.id;
                mapboxFeature.properties.category = 'Urban Roadside';
                mapboxFeature.properties.postcode = 'SA15 3NX';
                // mapboxFeature.properties.pm25 = sensorStatusIndex; //sensorsStatus[sensorStatusIndex].version
                // mapboxFeature.properties.airquality = 'GOOD';
                mapboxData.push (mapboxFeature);
                
            }
            return mapboxData
        }

    },
})


/*
{
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [-4.2283417,51.7089602]
                },
                'properties': {
                    'name': 'Llanelli',
                    'id': 1001,
                    'category': 'Urban Roadside',
                    'postcode': 'SA15 3NX',
                        'pm25': 10,
                        'airquality': 'GOOD',
                },

            },

*/