<template>
    <div id="mapContainer" :style="style"></div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    export default {
        name: 'mapbox',
        props: {
            width: {
                type: String,
                default: '100%',
                required: false
            },
            height: {
                type: String,
                default: '100%',
                required: false
            },
        },
        data: () => ({
            accessToken: 'pk.eyJ1IjoidmluZGljbyIsImEiOiJjanY2a3VpanQwMHZhM3lwZm1oaWxhemtnIn0.D8js3Hp9ss34dscb5Z1Y9Q',
            map: {},
            features: [],
            geojson: {}
        }),
        computed: {
            ...mapState('mapbox_store/', {
                mapbox: state => state.map,
                marker: state => state.marker
            }),
            ...mapGetters({
                GET_mapboxData: 'GET_mapboxData',
                GET_features: 'mapbox_store/GET_features',
                GET_marker: 'mapbox_store/GET_marker',
                GET_nodes: 'mapbox_store/GET_nodes',
                GET_nodesPM: 'mapbox_store/GET_nodesPM',
            }),
            style() {
                return `width: ${this.width} !important; height: ${this.height} !important;`
            }
        },
        watch: {
            GET_marker: {
                handler: function() {
                    switch (this.GET_marker.method) {
                        case 'create':
                            this.geojson = []
                            this.createNode(this.GET_marker.data.id, this.GET_marker.data)
                            break;
                        case 'update':
                            //console.log(`id: ${this.GET_marker.properties.id} | ${JSON.stringify(this.GET_marker.properties, null, 2)}`)
                            this.updateNode(this.GET_marker.properties.id, this.GET_marker.properties)
                            break;
                        case 'delete':
                            this.geojson = []
                            this.deleteNode(this.GET_marker.data.id)
                            break;
                        default:
                            // do nothing
                            break;
                    }
                    //console.log('update GET_marker  ' + JSON.stringify(this.GET_marker, null, 2))

                }, deep: true
            },
            GET_nodes: {
                handler() {
                    let t = this;
                    for (var i = 0; i < t.GET_nodes.length; i++) {
                        var node = t.GET_nodes[i];
                        //console.log('node = ' + node.id)
                        //console.log(JSON.parse(JSON.stringify(node)))
                        t.createNode(node.id, JSON.parse(JSON.stringify(node)))
                    }
                }, deep: true
            },
            GET_nodesPM: {
                handler() {
                    let t = this;
                    //console.log('nodesPM updated')
                    if (t.GET_nodesPM !== undefined) {
                        //console.log('t.GET_nodesPM.length = ' + t.GET_nodesPM.length)
                    for (var i = 0; i < t.GET_nodesPM.length; i++) {
                        var node = t.GET_nodesPM[i];
                        node.pm25 = 0;
                        //console.log('node = ' + JSON.stringify(node, null, 2))
                        var properties = {}
                         switch (true) {
                                case ((node.pm25 >= 0) && (node.pm25 <= 24)):
                                    properties.airquality = "GOOD";
                                    break;
                                case ((node.pm25 > 24) && (node.pm25 <= 49)):
                                    properties.airquality = "MODERATE";
                                    break;
                                case ((node.pm25 > 49) && (node.pm25 <= 300)):
                                    properties.airquality = "POOR";
                                    break;

                            }
                        // properties.airquality = 'GOOD';
                        properties.pm25 = '' //node.pm25 //
                        properties.temp = node.temp
                        properties.humidity = node.humidity
                        properties.updated = node.updated
                        t.updateNode(node.id, properties)
                        //console.log(JSON.parse(JSON.stringify(node)))
//                        t.createNode(node.id, JSON.parse(JSON.stringify(node)))
                    }
                    }

                }, deep: true
            },

            mapbox: {
                handler() {
                    this.createMap();
                }, deep: true
            }
        },
        // watch: {
        //     marker: {
        //         handler() {
        //             //console.log('GET_marker = ' + JSON.stringify(this.GET_marker, null, 2))
        //         }, deep: true
        //     },
        //     mapbox: {
        //         handler() {
        //             this.createMap();
        //         }, deep: true
        //     }
        // },
        methods: {
            createMap() {
                //console.log('createmap')
                this.$mapboxgl.accessToken = this.accessToken;
                this.map = new this.$mapboxgl.Map({
                  container: "mapContainer",
                  style: this.mapbox.style,
                  center: this.mapbox.center,
                  zoom: this.mapbox.zoom,
                });
                this.map.addControl(new this.$mapboxgl.NavigationControl()); // Add Navigation Controls
                //this.addMapLayer();

            },
            alert(props) {
                alert(JSON.stringify(props, null, 2))
            },
            // getFeatures () {
            //     var t = this;
            //     return new Promise(function (resolve) {
            //         console.log('GET_mapboxData = ' + t.GET_mapboxData)
            //         t.features = t.GET_mapboxData
            //         //console.log(t.GET_features)
            //         resolve()
            //     })
            // },
            deleteNode(id) {
                var t = this;
                if(typeof t.map.getLayer(id) !== 'undefined') {
                    // Remove map layer & source.
                    t.map.removeLayer(id)
                    t.map.removeLayer(id+"-label")
                    t.map.removeLayer(id+"-value")
                    t.map.removeSource(id);
                }
            },
            updateNode(id, properties) {
                //console.log('update node')
                var t = this;
                var mapLayer = t.map.getLayer(id);
                if(typeof mapLayer !== 'undefined') {
                    //console.log('not undefined')
                    var features = t.map.queryRenderedFeatures({layers: [id]});
                    if (JSON.stringify(features) !== '[]') {
                        //console.log('existing features = ' + JSON.stringify(features, null, 2))
                        features[0].properties.airquality = properties.airquality
                        features[0].properties.pm25 = properties.pm25
                        features[0].properties.temp = properties.temp
                        features[0].properties.humidity = properties.humidity
                        
                        //features[0].properties.pm10 = properties.pm10
                        features[0].properties.updated = properties.updated
                        t.map.getSource(id).setData({
                            type: 'FeatureCollection',
                            features: features
                        });
                    }
                    //console.log('')
                }
                //                console.log('id = ' + id +  ' xproperties = ' + JSON.stringify(properties, null, 2))
                //console.log('id = ' + id + ' t = ' + JSON.stringify(t.map.getSource(id)))
                //if(typeof t.map.getLayer(id) !== 'undefined') {
                    // var features = []
                    // var feature = {}
                    // feature.properties = {}
                    // features.push (feature)
                    // t.map.getSource(id).setData({
                    //     type: 'FeatureCollection',
                    //     features: features
                    // });
                //}
            },
            createNode (id, data) {
                var t = this;
                t.geojson = data;
                //console.log('data = ' + JSON.stringify(data, null, 2))
                t.map.addSource(id, {
                'type': 'geojson',
                'data': t.geojson
                });
                // Add Marker Circle
                t.map.addLayer({
                    "id": id,
                    "type": "circle",
                    "layout": {},
                    "source": id,
                    "paint": {
                        "circle-radius": {
                            stops: [
                                /* 0 - The Earth | 3 - A continent | 4 - Large islands | 6 - Large rivers | 10 - Large roads | 15 - Buildings */
                                  [0, 15],
                                  [4, 12],
                                  [6, 22],
                                  [12, 22],
                                ],
                                base: 2
                              },
                              "circle-opacity": 1,
                                "circle-color": [ "match", ["get", "airquality"], "GOOD", "#582468", "MODERATE", "#E65100",  "POOR", "#B71C1C", /* other */ '#ccc' ]
                            },
                })
                //#4CAF50
                //                                 "circle-color": [ "match", ["get", "airquality"], "1GOOD", "#C8E6C9", "2GOOD", "#81C784", "3GOOD", "#4CAF50", "4MODERATE", "#FFC107", "5MODERATE", "#FFA000", "6MODERATE", "#FF6F00", "7POOR", "#F44336", "8UNHEALTHY", "#D32F2F", "9VERY UNHEALTHY", "#B71C1C", /* other */ '#ccc' ]
                // Add Marker Label
                // t.map.addLayer({
                //     "id": id + "-label",
                //     "type": "symbol",
                //     "source": id,
                //     "layout": {
                //         "text-field": ["get", "name"],
                //         "text-font": [
                //             "Open Sans Semibold",
                //             "Arial Unicode MS Bold"
                //         ],
                //         "text-offset": [0, 2.25],
                //         "text-anchor": "top"
                //            },
                //             "paint": {
                //                 "icon-halo-width": 10,
                //                 "text-color": "#582468",
                //                 "text-halo-color": "#fff",
                //                 "text-halo-width": 20
                //             },
                // });
                // Add Marker Value
                t.map.addLayer({
                    "id": id + "-value",
                    "type": "symbol",
                    "source": id,
                    "layout": {
                        "text-field": ["get", "pm25"],
                        "text-size": 12,
                        "text-font": [
                            "Open Sans Semibold",
                            "Arial Unicode MS Bold"
                        ],
                    },
                    "paint": {
                        "icon-halo-width": 10,
                        "text-color": "#ffffff",
                    },
                });

                // remove click feature
                // t.map.on('click', id, function (e) {
                //     var features = t.map.queryRenderedFeatures(e.point, {
                //         layers: [id]
                //     });
                //     // var features2 = t.map.querySourceFeatures(e.point, {
                //     //     layers: [id]
                //     // });
                    
                //     console.log('features = ' + JSON.stringify(features, null, 2))
                //     //console.log('Point Clicked')
                //     t.ACT_update_layerSelected(features[0].properties)
                //     //console.log(features[0].properties)
                // });

                t.map.on('mouseenter', id, () => {
                    t.map.getCanvas().style.cursor = 'pointer'
                })
                t.map.on('mouseleave', id, () => {
                    t.map.getCanvas().style.cursor = ''
                })

                // t.map.on('dblclick', id, () => {
                //     console.log('dblclick detected')
                //     t.map.flyTo({
                //         // These options control the ending camera position: centered at
                //         // the target, at zoom level 9, and north up.
                //         //center: ,
                //         zoom: 9,
                //         bearing: 0,

                //         // These options control the flight curve, making it move
                //         // slowly and zoom out almost completely before starting
                //         // to pan.
                //         speed: 0.2, // make the flying slow
                //         curve: 1, // change the speed at which it zooms out

                //         // This can be any easing function: it takes a number between
                //         // 0 and 1 and returns another number between 0 and 1.
                //         easing: function (t) {
                //             return t;
                //         },
                //         // // this animation is considered essential with respect to prefers-reduced-motion
                //         essential: true
                //     });

                // })

            },
        },
        mounted() {
            //console.log('mapbox component mounted')
            this.createMap
        }

    }
</script>