<template>
  <v-app class="">
    <v-main class="">
      <!--Think Air logo-->
      <v-img
        src="./assets/logo.png"
        min-width="64"
        width="10vw"
        max-width="200"
        style="
          z-index: 99999 !important;
          top: 20px !important;
          left: 20px !important;
        "
      />

      <!--Sensor Sponsor-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div
          v-if="popup && activeSensor.sponsorName !== undefined"
          style="
            border: 2px solid #582468 !important;
            border-radius: 3px !important;
            position: absolute !important;
            z-index: 99999 !important;
            top: 20px !important;
            right: 20px !important;
          "
        >
          <div>
            <a :href="activeSensor.sponsorURL" target="_blank">
              <v-img :src="getImgUrl(activeSensor.sponsorLogo)" width="200" style="margin-left: auto; margin-right: auto;" />
            </a>
          </div>
          <div
            class="text-center pa-2 font-weight-normal"
            style="background: #582468 !important; color: #ffffff !important"
          >
            <strong>Sensor Sponsor</strong><br />{{ activeSensor.sponsorName }}
          </div>
        </div>
      </transition>

      <!--Sensor Data-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div
          v-if="popup && $vuetify.breakpoint.width >= 1600"
          style="
            background: #582468 !important;
            width: 100% !important;
            height: 100px !important;
            z-index: 999999 !important;
            position: absolute;
            bottom: 0 !important;
            left: 0 !important;
          "
        >
          <v-row
            class="
              white--text
              ma-2
              rounded
              font-weight-light
              d-flex
              justify-space-between
            "
            no-gutters
            style="height: 85px !important"
          >
            <v-col cols="2" class="d-flex align-center">
              <div class="">
                <img
                  src="https://img.icons8.com/windows/42/ffffff/marker.png"
                />
              </div>
              <div class="text-h5 font-weight-light">
                {{ activeSensor.name }}
              </div>
            </v-col>
            <v-col cols="2" class="d-flex align-center pa-2">
              <div class="d-flex pr-2">
                <img
                  src="https://img.icons8.com/windows/42/ffffff/air-element.png"
                />
              </div>
              <div class="text-h5 text-center font-weight-light">
                Air Quality
              </div>
              <div
                :class="{
                  'green darken-1': activeSensor.airquality === 'GOOD',
                  'orange darken-4': activeSensor.airquality === 'MODERATE',
                  'red darken-4': activeSensor.airquality === 'POOR',
                }"
                class="
                  font-weight-bold
                  d-flex
                  align-center
                  text-h5
                  ml-4
                  pl-2
                  pr-2
                "
                style="height: 55px !important; border-radius: 5px !important"
              >
                {{ activeSensor.airquality }}
              </div>
              <!-- {{ activeSensor.airquality }} -->
            </v-col>
            <v-col cols="2" class="d-flex align-center pa-2">
              <div class="d-flex pr-2">
                <img
                  src="https://img.icons8.com/windows/42/ffffff/foam-bubbles.png"
                />
              </div>
              <div class="text-h5 font-weight-light">PM 2.5</div>
              <div
                :class="{
                  'green darken-1': activeSensor.airquality === 'GOOD',
                  'orange darken-4': activeSensor.airquality === 'MODERATE',
                  'red darken-4': activeSensor.airquality === 'POOR',
                }"
                class="
                  font-weight-bold
                  d-flex
                  align-center
                  text-h5
                  ml-4
                  pl-2
                  pr-2
                "
                style="height: 55px !important; border-radius: 5px !important"
              >
                {{ activeSensor.pm25 }}
              </div>
              <!-- {{ activeSensor.airquality }} -->
              <div class="pl-2 text-h6 font-weight-thin">µg/m3</div>
            </v-col>
            <v-col cols="2" class="d-flex align-center pa-2">
              <div class="d-flex pr-2">
                <img
                  src="https://img.icons8.com/windows/42/ffffff/temperature.png"
                />
              </div>
              <div class="text-h5 font-weight-light">Temperature</div>
              <div
                class="
                  purple
                  font-weight-bold
                  d-flex
                  align-center
                  text-h5
                  ml-4
                  pl-2
                  pr-2
                "
                style="height: 55px !important; border-radius: 5px !important"
              >
                {{ activeSensor.temp }}
              </div>
              <!-- {{ activeSensor.airquality }} -->
              <div class="pl-2 text-h6 font-weight-thin">°C</div>
            </v-col>
            <v-col cols="2" class="d-flex align-center pa-2">
              <div class="d-flex pr-2">
                <img src="https://img.icons8.com/windows/42/ffffff/wet.png" />
              </div>
              <div class="text-h5 font-weight-light">Humidity</div>
              <div
                class="
                  purple
                  font-weight-bold
                  d-flex
                  align-center
                  text-h5
                  ml-4
                  pl-2
                  pr-2
                "
                style="height: 55px !important; border-radius: 5px !important"
              >
                {{ activeSensor.humidity }}
              </div>
              <!-- {{ activeSensor.airquality }} -->
              <div class="pl-2 text-h6 font-weight-thin">%</div>
              <div
                @click="popup = !popup"
                style="align: right !important; height: 75px !important"
              >
                <img
                  src="https://img.icons8.com/windows/24/ffffff/multiply.png"
                />
              </div>
            </v-col>
            <!-- <v-col cols="12" class="d-flex white--text body-1 ">
                            <div class="font-weight-bold">{{ activeSensor.category.toUpperCase() }}</div>
                            <v-spacer />
                            <div class="font-weight-normal">{{ activeSensor.postcode.substr(0,4) }}</div>
                        </v-col> -->
          </v-row>
        </div>

        <div
          v-if="popup && $vuetify.breakpoint.width < 1600"
          style="
            z-index: 999999 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 32px;
            position: absolute;
            top: 0 !important;
          "
        >
          <div
            style="
              background: #582468 !important;
              z-index: 999999 !important;
              height: 100%;
              max-height: 512px;
              width: 512px;
              padding: 16px !important;
              border-radius: 16px;
              position: relative;
            "
          >
            <v-row
              class="white--text ma-2 rounded font-weight-light"
              no-gutters
              style="
                display: grid;
                grid-template-rows: auto repeat(4, 1fr);
                height: calc(100% - 16px);
              "
            >
              <!--Sensor name | Close icon-->
              <v-col cols="12" class="d-flex align-center">
                <!--Icon-->
                <div class="">
                  <img
                    src="https://img.icons8.com/windows/42/ffffff/marker.png"
                  />
                </div>

                <!--Location name-->
                <div class="text-h5 font-weight-regular">
                  {{ activeSensor.name }}
                </div>

                <v-spacer />

                <!--Close icon-->
                <div
                  @click="popup = !popup"
                  style="align: right !important; height: 75px !important"
                >
                  <img
                    src="https://img.icons8.com/windows/24/ffffff/multiply.png"
                  />
                </div>
              </v-col>

              <!--Air Quality-->
              <v-col cols="12" class="d-flex align-center pa-2">
                <div class="d-flex pr-2">
                  <img
                    src="https://img.icons8.com/windows/42/ffffff/air-element.png"
                  />
                </div>
                <div class="text-h5 text-center font-weight-light">
                  Air Quality
                </div>
                <div
                  :class="{
                    'green darken-1': activeSensor.airquality === 'GOOD',
                    'orange darken-4': activeSensor.airquality === 'MODERATE',
                    'red darken-4': activeSensor.airquality === 'POOR',
                  }"
                  class="
                    font-weight-bold
                    d-flex
                    align-center
                    text-h5
                    ml-4
                    pl-2
                    pr-2
                  "
                  style="height: 55px !important; border-radius: 5px !important"
                >
                  {{ activeSensor.airquality }}
                </div>
                <!-- {{ activeSensor.airquality }} -->
              </v-col>

              <!--Active Sensor PM25-->
              <v-col cols="12" class="d-flex align-center pa-2">
                <div class="d-flex pr-2">
                  <img
                    src="https://img.icons8.com/windows/42/ffffff/foam-bubbles.png"
                  />
                </div>
                <div class="text-h5 font-weight-light">PM 2.5</div>
                <div
                  :class="{
                    'green darken-1': activeSensor.airquality === 'GOOD',
                    'orange darken-4': activeSensor.airquality === 'MODERATE',
                    'red darken-4': activeSensor.airquality === 'POOR',
                  }"
                  class="
                    font-weight-bold
                    d-flex
                    align-center
                    text-h5
                    ml-4
                    pl-2
                    pr-2
                  "
                  style="height: 55px !important; border-radius: 5px !important"
                >
                  {{ activeSensor.pm25 }}
                </div>
                <!-- {{ activeSensor.airquality }} -->
                <div class="pl-2 text-h6 font-weight-thin">µg/m3</div>
              </v-col>

              <!--Active Sensor TEMP-->
              <v-col cols="12" class="d-flex align-center pa-2">
                <div class="d-flex pr-2">
                  <img
                    src="https://img.icons8.com/windows/42/ffffff/temperature.png"
                  />
                </div>
                <div class="text-h5 font-weight-light">Temperature</div>
                <div
                  class="
                    purple
                    font-weight-bold
                    d-flex
                    align-center
                    text-h5
                    ml-4
                    pl-2
                    pr-2
                  "
                  style="height: 55px !important; border-radius: 5px !important"
                >
                  {{ activeSensor.temp }}
                </div>
                <!-- {{ activeSensor.airquality }} -->
                <div class="pl-2 text-h6 font-weight-thin">°C</div>
              </v-col>

              <!--Active Sensor HUMIDITY-->
              <v-col cols="12" class="d-flex align-center pa-2">
                <div class="d-flex pr-2">
                  <img src="https://img.icons8.com/windows/42/ffffff/wet.png" />
                </div>
                <div class="text-h5 font-weight-light">Humidity</div>
                <div
                  class="
                    purple
                    font-weight-bold
                    d-flex
                    align-center
                    text-h5
                    ml-4
                    pl-2
                    pr-2
                  "
                  style="height: 55px !important; border-radius: 5px !important"
                >
                  {{ activeSensor.humidity }}
                </div>
                <!-- {{ activeSensor.airquality }} -->
                <div class="pl-2 text-h6 font-weight-thin">%</div>
              </v-col>

              <!-- <v-col cols="12" class="d-flex white--text body-1 ">
                                <div class="font-weight-bold">{{ activeSensor.category.toUpperCase() }}</div>
                                <v-spacer />
                                <div class="font-weight-normal">{{ activeSensor.postcode.substr(0,4) }}</div>
                            </v-col> -->
            </v-row>
          </div>
        </div>
      </transition>

      <!--Map Vew-->
      <mapbox
        width="100%"
        height="100%"
        class="maplayer"
        style="
          z-index: 999 !important;
          top: 0px !important;
          left: 0px !important;
        "
      ></mapbox>

      <!-- <pre>{{ nodes }}</pre> -->
      <!-- <div class="prelayer white black--text">
                <v-btn @click="createNode()">Create Node</v-btn>
                <v-btn @click="updateNode()">Update Node</v-btn>
                <v-btn @click="deleteNode()">Delete Node</v-btn>
            </div> -->
      <!-- <pre>
               GETMAPBOX DATA: {{ GET_mapboxData }} -->

      <!-- {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [-4.2283417,51.7089602]
                            },
                            'properties': {
                                'name': 'Llanelli',
                                'id': 1001,
                                'category': 'Urban Roadside',
                                'postcode': 'SA15 3NX',
                                    'pm25': 10,
                                    'airquality': 'GOOD',
                            },

                        }, -->
      <!-- </pre>

            GET_nodesInstall: <pre>{{ GET_nodesInstall }}</pre></div> -->
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  // * DATA
  data: () => ({
    popup: false,
    particulates: [],
    activeSensor: {
      name: "Shirehampton",
      id: null,
      category: "Urban Roadside",
      postcode: "SA15 3NX",
      pm25: 10,
      pm10: 12,
      temp: 19,
    },
    nodesInstall: [],
    activeSensorData: {
      id: 1001,
      temp: 14,
      pm10: 3.5,
      pm25: 2.5,
    },
    sensorData: [
      {
        id: 1001,
        temp: 14,
        pm10: 3.5,
        pm25: 2.5,
      },
      {
        id: 1002,
        temp: 13,
        pm10: 5.5,
        pm25: 3.5,
      },
    ],
    properties: {
      pm25: "3.5",
      airquality: "BAD",
    },
    geojson: {
      id: "1001",
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [-4.325314954634859, 51.70371282706481],
          },
          properties: {
            name: "Llanelli Trostre",
            id: 1001,
            category: "Urban Roadside",
            postcode: "SA15 3NX",
            pm25: "",
            airquality: "",
          },
        },
      ],
    },

    nodes: [
      {
        id: "1000000077b41ed3",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.94744, 51.6326],
            },
            properties: {
              name: "Swansea AURN- Test 1",
              id: "1000000077b41ed3",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000ff4775f2",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.94744, 51.6326],
            },
            properties: {
              name: "Swansea AURN- Test 2",
              id: "10000000ff4775f2",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "1000000029d01926",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.2033476793496414, 51.512307997551936],
            },
            properties: {
              name: "Cardiff Birchgrove",
              id: "1000000029d01926",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000a9298ccb",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.1484225553717975, 51.843175592915195],
            },
            properties: {
              name: "Llanarthney",
              id: "10000000a9298ccb",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000b9b7d36f",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.839683081482502, 52.59324380511135],
            },
            properties: {
              name: "Machynlleth",
              id: "10000000b9b7d36f",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000fa4ac8f1",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-2.991891528378587, 51.603878886078434],
            },
            properties: {
              name: "Newport Brynglass",
              id: "10000000fa4ac8f1",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Niche",
              sponsorLogo: "niche.png",
              sponsorURL: "https://www.nicheifa.co.uk/",
            },
          },
        ],
      },
      {
        id: "100000002a75b103",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.947374, 51.632696],
            },
            properties: {
              name: "Swansea Hafod",
              id: "100000002a75b103",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000a9b84f18",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.160786012232119, 51.68426232766927],
            },
            properties: {
              name: "Llanelli AQMA",
              id: "10000000a9b84f18",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000004adaa0a2",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.262542303540884, 51.89603358827968],
            },
            properties: {
              name: "Peniel",
              id: "100000004adaa0a2",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000814c53d1",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.4025832, 51.9431943],
            },
            properties: {
              name: "Brecon",
              id: "10000000814c53d1",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Brecon RFC",
              sponsorLogo: "breconrfc.jpeg",
              sponsorURL: "http://www.breconrfc.co.uk/",
            },
          },
        ],
      },
      {
        id: "10000000073db155",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.1908629742352526, 51.66596974609927],
            },
            properties: {
              name: "Blackwood",
              id: "10000000073db155",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Blackwood RFC",
              sponsorLogo: "blackwoodrfc.png",
              sponsorURL: "https://blackwood.rfc.wales/",
            },
          },
        ],
      },
      {
        id: "10000000da5db0fc",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.767300113701215, 51.65148227984527],
            },
            properties: {
              name: "Cimla",
              id: "10000000da5db0fc",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000678017dc",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.475738488822829, 51.78525107033456],
            },
            properties: {
              name: "Laugharne",
              id: "10000000678017dc",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000009e56c57d",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.6984138, 51.7371214],
            },
            properties: {
              name: "Stepaside",
              id: "100000009e56c57d",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000002d7c5118",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.8592603, 51.7287376],
            },
            properties: {
              name: "Pontardawe",
              id: "100000002d7c5118",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000d03fdbcb",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.1277942, 51.6786195],
            },
            properties: {
              name: "Llanelli Trostre",
              id: "10000000d03fdbcb",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Scarlets",
              sponsorLogo: "scarlets.jpeg",
              sponsorURL: "https://www.scarlets.wales",
            },
          },
        ],
      },
      {
        id: "1000000029568a26",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.594952070770107, 51.54465349438938],
            },
            properties: {
              name: "Bridgend Tondu",
              id: "1000000029568a26",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "A&R Cleaning",
              sponsorLogo: "andrcleaning.jpeg",
              sponsorURL: "https://www.arcs-cleaning.co.uk/",
            },
          },
        ],
      },
      {
        id: "100000001d470a29",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.02846503538876, 51.8068794250571],
            },
            properties: {
              name: "Llandybie",
              id: "100000001d470a29",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000002cd3dafe",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [1.120833, 52.133915],
            },
            properties: {
              name: "Hemingstone",
              id: "100000002cd3dafe",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000009d3585eb",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.421342366089083, 52.24078062424095],
            },
            properties: {
              name: "Rhayader",
              id: "100000009d3585eb",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "1000000015889132",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.494878473847517, 53.33754117952059],
            },
            properties: {
              name: "Anglesey",
              id: "1000000015889132",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000002b2cb70e",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.181778269113366, 51.451301096893545],
            },
            properties: {
              name: "Cardiff Bay",
              id: "100000002b2cb70e",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Cardiff Devils",
              sponsorLogo: "cardiffdevils.jpeg",
              sponsorURL: "https://www.cardiffdevils.com/",
            },
          },
        ],
      },
      {
        id: "1000000042926094",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.940513147051112, 53.00403659097948],
            },
            properties: {
              name: "Blaenau Ffestiniog",
              id: "1000000042926094",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Zip World",
              sponsorLogo: "zipworld.jpg",
              sponsorURL: "https://www.zipworld.co.uk/",
            },
          },
        ],
      },
      {
        id: "10000000ba1fe079",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.90487810273132, 51.8047939110077],
            },
            properties: {
              name: "Garnant",
              id: "10000000ba1fe079",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000c792dc04",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.062926476369093, 53.16850548673906],
            },
            properties: {
              name: "Bethesda",
              id: "10000000c792dc04",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000641a0704",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.062241, 51.8800588],
            },
            properties: {
              name: "Aberglasney",
              id: "10000000641a0704",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000dea5449b",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.2873166, 52.2082838],
            },
            properties: {
              name: "Aberaeron",
              id: "10000000dea5449b",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000006d1db90b",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.7301358, 51.8628445],
            },
            properties: {
              name: "Llandissilio",
              id: "100000006d1db90b",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000003635470e",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.6469285, 52.0842223],
            },
            properties: {
              name: "Cardigan",
              id: "100000003635470e",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000003d525ae1",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-3.6709846638615162, 51.791784663247356],
            },
            properties: {
              name: "Coelbren",
              id: "100000003d525ae1",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000a081759a",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-4.2885414150100045, 51.729583890537285],
            },
            properties: {
              name: "Kidwelly",
              id: "10000000a081759a",
              category: "Urban Roadside",
              postcode: "SA15 3NX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000d17e77fa",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-1.91421360468924, 52.430773742169606],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 6",
              id: "10000000d17e77fa",
              category: "Urban Roadside",
              postcode: "",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "100000004a786055",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-1.5420754323686823, 52.397703892975734],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 8",
              id: "100000004a786055",
              category: "Urban Roadside",
              postcode: "CV5 6AG",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "1000000081ce29ff",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-0.8996823011516623, 52.3070761488626],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 15",
              id: "1000000081ce29ff",
              category: "Urban Roadside",
              postcode: "NN6 9AA",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000e60c1003",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-1.38327537214577, 52.10721712166668],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 5",
              id: "10000000e60c1003",
              category: "Urban Roadside",
              postcode: "OX17 1HT",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "1000000073bccf35",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-1.8351148818065903, 52.56567560507448],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 9",
              id: "1000000073bccf35",
              category: "Urban Roadside",
              postcode: "B74 2YT",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "1000000028f3d388",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-1.9195880731251227, 52.22022183959969],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 12",
              id: "1000000028f3d388",
              category: "Urban Roadside",
              postcode: "B49 5LJ",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "1000000003560815",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-2.2725872050525577, 52.39431692262484],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 13",
              id: "1000000003560815",
              category: "Urban Roadside",
              postcode: "DY11 5PT",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
            },
          },
        ],
      },
      {
        id: "10000000e8ea8f18",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [-1.4776292535321338, 52.41461610346368],
            },
            properties: {
              name: "Thriving Hive - Block 1 Box 14",
              id: "10000000e8ea8f18",
              category: "Urban Roadside",
              postcode: "CV2 4JX",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Thriving Hive",
              sponsorLogo: "thrivinghive.png",
              sponsorURL: "https://www.thrivinghive.co.uk/",
            },
          },
        ],
      },
      {
        id: "100000002ccc1262",
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [7.093193833937996, 50.72522162688292],
            },
            properties: {
              name: "Poppelsdorfer Schloss",
              id: "100000002ccc1262",
              category: "Urban Roadside",
              postcode: "53115 Bonn",
              pm25: "",
              pm10: "",
              temp: "",
              update: "",
              airquality: "",
              sponsorName: "Gesponsert von Gärten der Universität Bonn",
              sponsorLogo: "LogoBotGaert.png",
              sponsorURL: "https://en.wikipedia.org/wiki/Poppelsdorf_Palace",
            },
          },
        ],
      },
    ],
  }),
  // * COMPUTED
  computed: {
    ...mapState("mapbox_store/", {
      layerSelected: (state) => state.layerSelected,
    }),
    ...mapGetters({
      GET_nodesInstall: "GET_nodesInstall",
      GET_mapboxData: "GET_mapboxData",
      GET_nodes: "mapbox_store/GET_nodes",
    }),
  },
  // * WATCH
  watch: {
    // GET_mapboxData : {
    //     handler() {
    //         console.log('length:' + this.GET_mapboxData)
    //         // console.log(JSON.stringify(this.GET_mapboxData, null, 2))
    //     }, deep: true
    // },
    layerSelected: {
      handler() {
        //console.log('layerSelecter = ' + JSON.stringify(this.layerSelected))
        this.popup = true;
        this.activeSensor = this.layerSelected;
        //this.activeSensorData = this.getSensorData(this.layerSelected.id)
      },
      deep: true,
    },
    // GET_nodes: {
    //     handler() {
    //         console.log('NODES CREATED')
    //         this.getNodesPM;
    //     }, deep: true
    // }
  },
  // * METHODS
  methods: {
    ...mapActions({
      action_nodesStatus: "action_nodesStatus",
      action_nodesInstall: "action_nodesInstall",
    }),
    createNode() {
      this.MIX_marker_create("1001", JSON.parse(JSON.stringify(this.geojson)));
    },
    updateNode() {
      this.MIX_marker_update(
        "1001",
        JSON.parse(JSON.stringify(this.properties))
      );
    },
    deleteNode() {
      this.MIX_marker_delete("1001", {});
    },
    getSensorData: function (id) {
      var index = this.sensorData.findIndex(function (e) {
        return e.id === id;
      });
      return this.sensorData[index];
    },

    getImgUrl(logopath) {
      return require("./assets/" + logopath);
    },

    getNodeData: async function () {
      let t = this;
      return new Promise(function (resolve) {
        var url = "https://tadb.vindico.cloud/api/v1/taenodes";
        //var url = 'https://tadb.vindico.cloud/api/v1/taedata?query=SELECT LAST("value"),* FROM PM25 GROUP BY id';
        t.$axios.get(url).then((response) => {
          resolve(response.data);
        });
      });
    },
    getNodesPM: async function () {
      let t = this;
      //console.log('getNodesPM')
      t.particulates = await t.getNodeData();
      //console.log('got particulates = ' + JSON.stringify(t.particulates, null, 2))
      t.MIX_nodesPM(t.particulates);
    },

    // MQTT On Connect
    mqttOnConnect() {
      //this.$store.commit('update_mqttServerStatus',  'Connected')
      console.log("Connected to Think Air MQTT");
      this.$mqttClient.subscribe("thinkair/nodes/+/install");
      var t = this;
      setTimeout(function () {
        t.$mqttClient.subscribe("thinkair/nodes/+/status");
      }, 10000);
      console.log("subscribed");
    },
    mqttOnFailure(error) {
      //      this.$store.commit('update_mqttServerStatus',  'Failed')
      console.log("mqtt server failed" + JSON.stringify(error, null, 2));
      // TODO - Reconnected on Failure
    },
    mqttOnMessage(message) {
      //var t = this
      switch (true) {
        case message.destinationName.includes("thinkair/nodes/"):
          var topicSplit = message.destinationName.split("/");
          var category = topicSplit[topicSplit.length - 1];
          //console.log('category = ' + category)
          var payload;
          switch (category) {
            case "install":
              payload = JSON.parse(message.payloadString);
              var node = {};
              node.id = payload.id;
              node.type = "FeatureCollection";
              node.features = [];
              var feature = {};
              feature.type = "Feature";
              feature.geometry = {};
              feature.geometry.type = "Point";
              feature.geometry.coordinates = [
                payload.location.lon,
                payload.location.lat,
              ];
              feature.properties = {};
              feature.properties.name = payload.name;
              feature.properties.id = payload.id;
              feature.properties.category = "Urban Roadside";
              feature.properties.postcode = "SA15 3NX";
              feature.properties.pm25 = "";
              feature.properties.pm10 = "";
              feature.properties.temp = "";
              feature.properties.humidity = "";
              feature.properties.updated = "";
              feature.properties.airquality = "";
              node.features.push(feature);
              //this.nodes.push (node)
              this.MIX_marker_create(
                payload.id,
                JSON.parse(JSON.stringify(node))
              );
              break;
            case "status":
              console.log('status')
              payload = JSON.parse(message.payloadString);
              var properties = {};
              //console.log('ID: ' + payload.id + JSON.stringify(payload.sensors))
              for (var i = 0; i < payload.sensors.length; i++) {
                switch (true) {
                  case payload.sensors[i].name === "PM25":
                    properties.pm25 = payload.sensors[i].value;
                    break;
                  case payload.sensors[i].name === "TEMPERATURE":
                    properties.temp = payload.sensors[i].value;
                    break;
                  case payload.sensors[i].name === "PM10":
                    properties.pm10 = payload.sensors[i].value;
                    break;
                }
              }
              properties.updated = payload.updated;
              switch (true) {
                case properties.pm25 >= 0 && properties.pm25 <= 24:
                  properties.airquality = "GOOD";
                  break;
                case properties.pm25 > 24 && properties.pm25 <= 49:
                  properties.airquality = "MODERATE";
                  break;
                case properties.pm25 > 49 && properties.pm25 <= 300:
                  properties.airquality = "POOR";
                  break;
              }
              // properties.pm25 = ''
              // console.log('properties = ' + JSON.stringify(properties))
              this.MIX_marker_update(
                payload.id,
                JSON.parse(JSON.stringify(properties))
              );
              //t.action_nodesStatus (message)
              break;
          }
          break;
        default:
          //console.log('Topic Not Matched');
          break;
      }
    },
  },
  // * MOUNTED
  async mounted() {
    let t = this;
    //var particulates = await this.getNodeData();
    //console.log('particulates = ' + JSON.stringify(particulates, null, 2))

    // var clientid = this.$moment().format('x')
    // this.$mqttClient = new this.$Paho.Client('tamosquitto.vindico.cloud', 8083, clientid);
    // var options = {
    //     useSSL: true,
    //     userName: 'thinkair',
    //     password: 'TAsa13snMQTT',
    //     timeout: 3,
    //     onSuccess: this.mqttOnConnect,
    //     onFailure: this.mqttOnFailure,
    // };
    // this.$mqttClient.connect(options);
    // this.$mqttClient.onMessageArrived = this.mqttOnMessage

    //                console.log('particulates.length = ' + JSON.stringify(particulates.data, null, 2))

    t.MIX_update_map();

    setTimeout(async function () {
      await t.MIX_nodes(t.nodes);
      setTimeout(async function () {
        t.getNodesPM();
        setInterval(function () {
          t.getNodesPM();
        }, 5000);
      }, 2000);

      // for (var i = 0; i < t.nodes.length; i++) {
      //     var node = t.nodes[i];
      //     //console.log('node = ' + node.id)
      //     //console.log(JSON.parse(JSON.stringify(node)))
      //      t.MIX_marker_create(node.id, JSON.parse(JSON.stringify(node)))
      // }
    }, 2000);
    //console.log('t.node.length' + t.nodes.length)
  },
};
</script>

<style scoped>
.blackback {
  background-color: black !important;
}

.maplayer {
  position: absolute;
  top: 0;
  z-index: 10 !important;
}

.datalayer {
  float: right !important;
  position: relative;
  top: 20px !important;
  right: 100px !important;
  z-index: 100 !important;
}

.prelayer {
  float: left !important;
  position: relative;
  top: 20px !important;
  left: 100px !important;
  z-index: 100 !important;
}

.infocard {
  background: rgba(88, 36, 104, 1) !important;
  /* rgba(88,36,104, 0.8) !important;  */
  border-radius: 10px !important;
}

.rounded {
  border-radius: 5px !important;
}

.backgroundgreen {
  background-color: green !important;
}

.backgroundwhite {
  background-color: white !important;
}

.airquality {
  position: fixed;
  top: 250px !important;
  left: 150px !important;
  z-index: 99999 !important;
}

.whitesquare {
  position: fixed;
  top: 180px !important;
  left: 0px !important;
  width: 100% !important;
  height: 75px !important;
  background-color: #ffffff;
}

.whitecircle {
  width: 180px !important;
  height: 180px !important;
  border: 10px solid white !important;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  padding: 5px;
  position: fixed;
  top: 75px;
  left: 200px;
}

.whitecircle2 {
  width: 170px !important;
  height: 170px !important;
  border: 10px solid white !important;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  padding: 5px;
  position: fixed;
  top: 90px;
  left: 100px;
}

.largecircle {
  width: 190px !important;
  height: 190px !important;
  border: 10px solid white !important;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  padding: 5px;
  position: fixed;
  top: 10px;
  left: 130px;
  z-index: 9999 !important;
}

.mediumcircle {
  width: 150px !important;
  height: 150px !important;
  border: 8px solid white !important;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  padding: 0px;
  position: fixed;
  top: 100px;
  left: 0px;
  z-index: 9997 !important;
}

.smallcircle {
  width: 100px !important;
  height: 100px !important;
  border: 6px solid white !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  padding: 5px;
  position: fixed;
  top: 55px;
  left: 80px;
  z-index: 99999 !important;
}

.icons8-cloud {
  display: inline-block;
  width: 100%;
  height: 300px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNTAiIGhlaWdodD0iNTAiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTE0NC45MjM0NCw3Ni41NjY4OGMwLjAyNjg4LC0wLjY1ODQ0IDAuMDUzNzUsLTEuMzAzNDQgMC4wNTM3NSwtMS45MzVjMCwtMjQuOTQgLTIwLjMwNDA2LC00NS4yMzA2MyAtNDUuMjQ0MDYsLTQ1LjIzMDYzYy0xNy4yMjY4OCwwIC0zMi45NjIxOSw5Ljk1NzE5IC00MC41MTQwNiwyNS4yMjIxOWMtMy45MTAzMSwtMi41OCAtOC40NzkwNiwtMy45Nzc1IC0xMy4yNzYyNSwtMy45Nzc1Yy0xMi43Mzg3NSwwIC0yMy4yMDY1Niw5LjkzMDMxIC0yNC4wNTMxMywyMi40Njc1Yy0xMy4wMDc1LDQuNzMgLTIxLjg4OTY5LDE3LjIxMzQ0IC0yMS44ODk2OSwzMS4yMjg3NWMwLDE4LjMyODc1IDE0LjkyOTA2LDMzLjI1NzgxIDMzLjI3MTI1LDMzLjI1NzgxaDEwOC4xMDQ2OWMxNi44OTA5NCwwIDMwLjYyNDA2LC0xMy43MzMxMiAzMC42MjQwNiwtMzAuNjI0MDZjMCwtMTUuNjgxNTYgLTExLjg1MTg3LC0yOC42NDg3NSAtMjcuMDc2NTYsLTMwLjQwOTA2eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+")
    50% 50% no-repeat;
  background-size: 100%;
}
</style>
