import Vue from 'vue';
import moment from 'moment';

const mapbox_store = {
    namespaced: true, //to be case sencitive
    state: {
        map: {
            center: [-4.2283417,51.7089602],
            style: "mapbox://styles/mapbox/outdoors-v11",
            zoom: 1,            
        },
        layerSelected: {
            id: '',
            name: ''
        },
        marker: {
            data: {},
            properties: {},
            method: '',
            updated: ''
        },
        nodes: [],
        nodesPM: []
    },
    // * MUTATIONS
    mutations:{
        MUT_marker_method (state, data) { state.marker.method = data },

        MUT_marker_create (state, data) { 
            state.marker.data = {}
            state.marker.properties = {}
            state.marker.data = data 
        },
        MUT_marker_update (state, data) { state.marker.properties = data },
        MUT_marker_delete (state, data) { 
            state.marker.data = {}
            state.marker.properties = {}
            state.marker.data = data 
        },

        MUT_marker_updated (state) { Vue.set(state.marker, 'updated', moment().format('x')) },
        
        MUT_update_map (state, data) { state.map = data },
        MUT_update_zoom (state, data) { state.map.zoom = data },
        MUT_update_layerSelected (state, data) { state.layerSelected = data },
        MUT_nodes (state, data) { state.nodes = data },
        MUT_nodesPM (state, data) { state.nodesPM = data },        
    },
    // * ACTIONS
    actions: {
        ACT_marker_create ({commit}, payload) {
            commit('MUT_marker_create', payload)
            commit('MUT_marker_method', 'create')
            commit('MUT_marker_updated');
        },
        ACT_marker_update ({commit}, payload) { 
            commit('MUT_marker_update', payload);
            commit('MUT_marker_method', 'update')
            commit('MUT_marker_updated');
        },
        ACT_marker_delete ({commit}, payload) { 
            commit('MUT_marker_delete', payload);
            commit('MUT_marker_method', 'delete')
            commit('MUT_marker_updated');
        },
        ACT_update_map ({ commit }, payload) { commit('MUT_update_map', payload) },
        ACT_update_zoom ({ commit }, payload) { commit('MUT_update_zoom', payload) },
        ACT_update_layerSelected ({ commit }, payload) { commit('MUT_update_layerSelected', payload) },
        ACT_nodes ({ commit }, payload) { commit('MUT_nodes', payload) },
        ACT_nodesPM ({ commit }, payload) { commit('MUT_nodesPM', payload) },

    },
    getters:{
        GET_marker (state) { return state.marker },
        GET_features (state) { return state.features },
        GET_nodes (state) { return state.nodes },
        GET_nodesPM (state) { return state.nodesPM },

        // currentAlert(state){
        //     return state.alert;
        // }
    },
};
export default mapbox_store;


// namespaced: true, //to be case sencitive
// // * STATE
// state: {
//     puDialog: { show: false, dynamicComponent: 'fsdialogtest', width: '500px', height: '500px' },
// },
// // * MUTATIONS
// mutations:{
//     MUT_puDialog(state, data) { state.puDialog = data },
// },
// // * GETTERS
// getters:{
//     GET_puDialog(state) { return state.puDialog },
// },
// // * ACTIONS
// actions: {
//     ACT_puDialog({commit}, payload){ commit('MUT_puDialog', payload) },
// }
// };
